import React from "react";
import { Account } from "../Account";
import { IAccountAndNotification } from "./AccountAndNotification";
import { Notification } from "../Notification";

const AccountAndNotification = (props: IAccountAndNotification.IProps) => {
    const { notificationProps, accountProps } = props;

    return (
        <>
            <Notification {...notificationProps} />
            <div className="position-relative">
                <Account {...accountProps} />
            </div>
        </>
    );
};

export default AccountAndNotification;
