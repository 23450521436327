import React from "react";
import { IoPerson } from "@react-icons/all-files/io5/IoPerson";
import { FaPhoneAlt } from "@react-icons/all-files/fa/FaPhoneAlt";
import { GiHamburgerMenu } from "@react-icons/all-files/gi/GiHamburgerMenu";
import { BsCaretDownFill as DownIcon } from "@react-icons/all-files/bs/BsCaretDownFill";
import { BsCaretUpFill as UpIcon } from "@react-icons/all-files/bs/BsCaretUpFill";
import { classNames } from "repoV2/utils/common/render/classNames";
import useCustomMediaQuery from "@Utils/hooks/useCustomMediaQuery";
import { useScrollToSectionOnMount } from "@Utils/hooks/rendering";
import styles from "./navbarClassic.module.scss";
import { INavbar } from "../../INavbar";
import AccountAndNotification from "../../components/AccountsAndNotification";

const NavbarClassic = ({
    hideMobHamMenu,
    isMobHamMenuOpen,
    isOfferingsDropdownOpen,
    onMobHamMenuClick,
    notificationProps,
    accountProps,
    navbarItems,
    rootElement,
}: INavbar.IChildProps): JSX.Element => {
    // Automatically scroll to the section mentioned in url after "#" after 1200 ms.
    // why 1200 ms?? - to wait for page to load
    useScrollToSectionOnMount(1200);
    const isMobile = useCustomMediaQuery(768);

    const DropdownItem = ({ props, content }: INavbar.INavbarItem) => (
        <a
            key={content.toString()}
            className={
                isMobile
                    ? classNames(
                          styles.link,
                          isOfferingsDropdownOpen
                              ? styles.expandLink
                              : styles.hiddenLink
                      )
                    : styles.dropdownLink
            }
            {...props}
        >
            {content}
        </a>
    );

    const DeskMobCommonNavbarItems = () => (
        <>
            {(
                [
                    "team",
                    "testimonials",
                    "gallery",
                    "blog",
                ] as Array<INavbar.INavbarItemKeys>
            ).map(navbarItemKey => {
                const navbarItem = navbarItems[navbarItemKey];
                return navbarItem.showItem ? (
                    <a
                        key={navbarItemKey}
                        className={styles.link}
                        {...navbarItem.props}
                    >
                        {navbarItem.content}
                    </a>
                ) : null;
            })}

            {navbarItems.externalLinks.map(item => (
                <a
                    {...item.props}
                    // item.content is string for externalLinks
                    key={item.content.toString()}
                    className={styles.link}
                >
                    {item.content}
                </a>
            ))}
        </>
    );

    const AccountAndNotificationProps = {
        notificationProps,
        accountProps,
    };

    return (
        <div className={styles.root} ref={rootElement}>
            <div className={styles.titleContainer}>
                <a
                    className={classNames(styles.title, "d-flex")}
                    {...navbarItems.home.props}
                >
                    {navbarItems.home.content}
                </a>
            </div>
            {isMobile ? null : (
                <div className={styles.pageLinks}>
                    <a className={styles.link} {...navbarItems.about.props}>
                        {navbarItems.about.content}
                    </a>
                    {navbarItems.eventListings.showItem ? (
                        <div
                            className={classNames(
                                styles.link,
                                styles.eventListings
                            )}
                            {...navbarItems.eventListings.props}
                        >
                            {navbarItems.eventListings.content}
                            {navbarItems.eventListings.showChildren ? (
                                <>
                                    &nbsp;
                                    <DownIcon />
                                    <div
                                        className={
                                            styles.eventListingsDropdownContainer
                                        }
                                    >
                                        {navbarItems.eventListings.children.map(
                                            navbarItem =>
                                                DropdownItem(navbarItem)
                                        )}
                                    </div>
                                </>
                            ) : null}
                        </div>
                    ) : null}

                    <DeskMobCommonNavbarItems />
                </div>
            )}
            <div className={styles.buttonSection}>
                <AccountAndNotification {...AccountAndNotificationProps} />

                {navbarItems.getInTouch.showItem ? (
                    <div
                        className={classNames(
                            !isMobile && styles.iconButton,
                            styles.button
                        )}
                        {...navbarItems.getInTouch.props}
                    >
                        {isMobile ? (
                            <FaPhoneAlt />
                        ) : (
                            navbarItems.getInTouch.content
                        )}
                    </div>
                ) : null}
                {navbarItems.login.showItem ? (
                    <div
                        className={classNames(styles.button)}
                        {...navbarItems.login.props}
                    >
                        {isMobile ? (
                            <IoPerson />
                        ) : (
                            <>{navbarItems.login.content}</>
                        )}
                    </div>
                ) : null}
                {isMobile && !hideMobHamMenu ? (
                    <div
                        className={classNames(
                            styles.button,
                            isMobHamMenuOpen && styles.active
                        )}
                        onClick={onMobHamMenuClick}
                    >
                        <GiHamburgerMenu />
                    </div>
                ) : null}
                {isMobile ? (
                    <div
                        className={classNames(
                            styles.mobileDropdownContainer,
                            isMobHamMenuOpen && styles.open
                        )}
                    >
                        <a className={styles.link} {...navbarItems.about.props}>
                            {navbarItems.about.content}
                        </a>
                        {navbarItems.eventListings.showItem ? (
                            <a
                                className={classNames(
                                    styles.link,
                                    styles.eventListings
                                )}
                                {...navbarItems.eventListings.props}
                            >
                                {navbarItems.eventListings.content}
                                &nbsp;&nbsp;
                                {navbarItems.eventListings.showChildren ? (
                                    <>
                                        {isOfferingsDropdownOpen ? (
                                            <UpIcon />
                                        ) : (
                                            <DownIcon />
                                        )}
                                    </>
                                ) : null}
                            </a>
                        ) : null}
                        {navbarItems.eventListings.showChildren
                            ? navbarItems.eventListings.children.map(
                                  navbarItem => DropdownItem(navbarItem)
                              )
                            : null}

                        <DeskMobCommonNavbarItems />
                        {navbarItems.login.showItem ? (
                            <a
                                className={classNames(styles.link)}
                                {...navbarItems.login.props}
                            >
                                {navbarItems.login.content}
                            </a>
                        ) : null}
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default NavbarClassic;
